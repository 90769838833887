import { useSessionData } from "@/stores/SessionDataContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useReportContext } from "@/stores/ReportContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface StoreListProps {
  id: number | null;
  setStoreId: (id: number | null) => void;
}

export default function StoreList({ id, setStoreId }: StoreListProps) {
  const { stores } = useSessionData();

  const handleTabChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId =
      event.target.value === "null" ? null : parseInt(event.target.value);
    setStoreId(selectedId);
  };

  return (
    <div className={"mx-8 mt-4"}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a Store
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 dark:bg-slate-900 dark:border-slate-600"
          onChange={handleTabChange}
          defaultValue={id || "null"}
        >
          <option value="null">All Stores</option>
          {stores.map((tab) => (
            <option key={tab.key} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-slate-600 rounded shadow"
          aria-label="Tabs"
        >
          <div
            onClick={() => setStoreId(null)}
            className={classNames(
              id === null
                ? "text-gray-200"
                : "text-gray-400 hover:text-gray-700",
              "rounded-l-lg",
              "group relative min-w-0 overflow-hidden bg-slate-800 py-3 px-6 text-center text-sm font-medium hover:bg-indigo-900 hover:text-indigo-200 focus:z-10 cursor-pointer",
            )}
            aria-current={id === null ? "page" : undefined}
          >
            <span>All Stores</span>
            <span
              aria-hidden="true"
              className={classNames(
                id === null ? "bg-indigo-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5",
              )}
            />
          </div>
          {stores.map((tab, tabIdx) => (
            <div
              key={tab.name}
              onClick={() => setStoreId(tab.id)}
              className={classNames(
                tab.id === id
                  ? "text-gray-200"
                  : "text-gray-400 hover:text-gray-700",
                tabIdx === stores.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 overflow-hidden bg-slate-800 py-3 px-6 text-center text-sm font-medium hover:bg-indigo-900 hover:text-indigo-200 focus:z-10 cursor-pointer",
              )}
              aria-current={tab.id === id ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.id === id ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
              />
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
