import React, { useState } from "react";
import RetailSummary from "@/pages/retail/RetailSummary";
import StoreList from "@/pages/retail/StoreList";
import DashCom from "@/components/DashCom";
import { useMediaQuery } from "react-responsive";
import { useSessionData } from "@/stores/SessionDataContext";

function RetailHome() {
  const [storeId, setStoreId] = useState<number | null>(null);
  const { restrict_store } = useSessionData();
  const singleStore = !!restrict_store;

  return (
    <div>
      {!singleStore && <StoreList id={storeId} setStoreId={setStoreId} />}
      <div className={"w-full h-full  px-8 py-4"}>
        <RetailSummary stores={storeId ? [storeId] : []} />
      </div>
    </div>
  );
}

export default RetailHome;
